import React, { useState } from 'react';
import { Box, Container, CssBaseline, useMediaQuery, useTheme, Grid, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import Slider from 'react-slick';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const items = [
  { title: '¿Cómo Participar?', description: '' },
  { title: 'Plazos y Premios', description: `` },
  { title: 'Sobre el SPOT', description: '' },
];

const QuienesPuedenParticipar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-flecha custom-next-flecha sobrefondo`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-flecha custom-prev-flecha sobrefondo`}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }

  const renderAccordion1 = () => (
    <div className='sobrefondo'>
      <Accordion className='sobrefondo' expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><strong>Inscripción</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p><strong>1. Inscripción:</strong></p>
            <p>Descargar el formulario de inscripción  <a href='/download/formulario.pdf' download >aquí</a>.</p>	
            <p>Firmarlo y enviarlo a <code>juegoresponsable@inprojuy.gob.ar</code> </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><strong>Instituciones Participantes</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p><strong>2. Instituciones Participantes:</strong></p>
            <p>Secundarias de gestión estatal, municipal, privada, cooperativa y comunitaria.</p>
            <p>Estudiantes de 13 a 18 años.</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><strong>Categorías</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p><strong>3. Categorías:</strong></p>
            <p>Ciclo Básico: 1ro y 2do año.</p>
            <p>Ciclo Orientado: 3ro, 4to y 5to año.</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><strong>Presentación de Spots</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p><strong>4. Presentación de Spots:</strong></p>
            <p>Envío de múltiples spots, por grado o individual.</p>
            <p>Participación gratuita.</p>
            <p>Consultas a <code>juegoresponsable@inprojuy.gob.ar</code>.</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><strong>Pasos para Presentar el Video</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p><strong>5. Pasos para Presentar el Video:</strong></p>
            <p>Subir el video a YouTube y copiar el enlace.</p>
            <p>Registrar el enlace en el formulario de inscripción en <a target="_blank" rel="noopener noreferrer" href="http://www.inprojuy.gob.ar">www.inprojuy.gob.ar</a>.</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><strong>Cesión de Imagen</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p><strong>6. Cesión de Imagen:</strong></p>
            <p>Enviar nota de Cesión de Imagen al correo o adjuntarla en la página web.</p>
            <p>Registrar nombre, apellido y DNI del menor y del tutor con firma y foto del DNI.</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );

  const renderAccordion = () => (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><strong>Formato de Video</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>Resolución HD (720)</li>
              <li>Formato libre: vertical, horizontal, filmado, animado o mixto</li>
              <li>Duración máxima: 2 minutos</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><strong>Dispositivos y Contenido</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>Usar cámaras, celulares, computadoras o dispositivos afines</li>
              <li>Filmaciones e imágenes propias y/o de archivo (de uso libre y gratuito)</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><strong>Requisitos de Creación</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>Creación intelectual exclusiva de los participantes</li>
              <li>No se aceptarán obras premiadas en otros concursos</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><strong>Participación del Alumnado</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>Grupal o individual</li>
              <li>Asistidos por docentes o personal de la institución</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><strong>Presentación de Videos</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>Temática del concurso</li>
              <li>Sin marcos, firmas o marcas de agua</li>
              <li>No material obsceno o inapropiado</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><strong>Menores de Edad</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>No filmar menores de edad</li>
              <li>Sólo de espaldas o sin enfocar el rostro</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><strong>Herramientas de Creación</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>Pueden usar aplicaciones (plantillas, IA, etc.)</li>
              {/* <li>Sin imágenes de menores en la filmación</li> */}
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );

  const renderAccordion2 = () => (
    <div>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><strong>Plazos del Concurso</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>Inicio: 3 de septiembre de 2024</li>
              <li>Finalización: 15 de abril de 2025</li>
              <li>Posibilidad de ampliación de plazos comunicada en la página web de los organizadores</li>
              <li>Dictamen del jurado en los siguientes 40 días corridos al cierre de inscripción</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><strong>Premios</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>Elementos deportivos para la escuela ganadora: 10 colchonetas, 10 pelotas de vóley, 10 pelotas de fútbol, 10 pelotas de básquet</li>
              <li>Para cada categoría ganadora: 3 tablets (máximo 3 tablets por grupo ganador)</li>
              <li>Total de premios: 20 colchonetas, 20 pelotas de vóley, 20 pelotas de fútbol, 20 pelotas de básquet y 6 tablets</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className='sobrefondo' expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><strong>Jurado</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>Integrado por representantes del Inprojuy, Secretaría de Salud Mental y Adicciones, influencer, productora del Festival Internacional de Cine en las Alturas, representante provincial de estudiantes 2023, y jefes de comunicación y juego responsable.</li>
              <li>Criterios de evaluación incluyen pertinencia, creatividad, claridad del mensaje, impacto emocional, calidad técnica y estética, originalidad, eficiencia publicitaria, banda sonora, uso de herramientas informáticas, y cumplimiento de tiempos.</li>
              <li>Decisión del jurado inapelable.</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className='sobrefondo' expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><strong>Resultados</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>Pre-selección de 6 spots (3 de cada categoría) para publicación en redes del INPROJUY</li>
              <li>Los ganadores se determinarán por niveles de interacción orgánica en redes (reproducciones, likes, comentarios y compartidas)</li>
              <li>Publicación de resultados en medios de difusión de LOS ORGANIZADORES y notificación a ganadores por correo electrónico</li>
              <li>Notificación a la escuela para la recepción de premios</li>
              <li>Aceptación del premio por escrito dentro de los 10 días de recibida la notificación</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Acordeón de Difusión */}
      <Accordion className='sobrefondo' expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><strong>Difusión</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>Consentimiento para la difusión de nombres, títulos e imágenes de las obras premiadas</li>
              <li>Uso de las obras solo para fines institucionales, culturales, educativos o de difusión</li>
              <li>Exclusión de uso con fines comerciales y derecho a retribución económica</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Acordeón de Derechos de Autor */}
      <Accordion className='sobrefondo' expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><strong>Derechos de Autor</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>Reconocimiento de autoría mediante la inscripción al concurso</li>
              <li>Integración de obras ganadoras en el Banco de Imágenes de LOS ORGANIZADORES con referencia a su autoría original</li>
              <li>Cesión de propiedad intelectual del diseño ganador a la Organización del Concurso</li>
              <li>Reserva de todos los derechos de propiedad y uso por parte de la Organización</li>
              <li>Exención de reclamos posteriores por parte del ganador</li>
              <li>Derecho de reproducción y modificaciones por parte de la Organización según necesidad</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );

  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
        {!isMobile && (
          <Box sx={{ width: '25%' }}>
            <Box sx={{ width: '100%', height: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
              <img src='/img/jxjbannercontexto.jpg' alt="Juga por jugar" style={{ maxWidth: '100%', height: 'auto', position: 'absolute', top: '0%' }} />
            </Box>
            <Box sx={{ height: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <Grid container spacing={2} sx={{ height: '100vh' }}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src='/img/jugaporjugar.png' alt="Juga por jugar" style={{ maxWidth: '100%', maxHeight: '80%' }} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
        <Box sx={{ width: isMobile ? '100%' : '75%', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ height: '20vh', bgcolor: '#d7412a', p: 1 }}>
            <h1 className='titulo2'>¡Participa En Nuestro Concurso!</h1>
          </Box>
          <Box sx={{ height: '50%', p: 2 }} >
            <Slider {...settings} className='fondo'>
              {items.map((item, index) => (
                <Box className='sobrefondo' key={index} sx={{ bgcolor: 'transparent', p: 1, opacity: 0.7 }}>
                  <h2 className='titconcurso'>{item.title}</h2>
                  {item.title === 'Sobre el SPOT' ? renderAccordion() : <p dangerouslySetInnerHTML={{ __html: item.description }}></p>}
                  {item.title === '¿Cómo Participar?' ? renderAccordion1() : <p dangerouslySetInnerHTML={{ __html: item.description }}></p>}
                  {item.title === 'Plazos y Premios' ? renderAccordion2() : <p dangerouslySetInnerHTML={{ __html: item.description }}></p>}
                </Box>
              ))}
            </Slider>
          </Box>
          <Box sx={{ bgcolor: 'grey.100', p: 2, marginTop: isMobile ? '16px' : '0' }}>
            <h1 className='titulo1'>
              Por dudas y consultas envíanos un mail a:
              <br />
              <a href="mailto:juegoresponsable@inprojuy.gob.ar">juegoresponsable@inprojuy.gob.ar</a>
              <Box sx={{ textAlign: 'center', marginTop: '10px' }}>
                <img src='/img/cine.png' alt="Descripción de la imagen" style={{ maxWidth: '100%' }} />
              </Box>
            </h1>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default QuienesPuedenParticipar;
