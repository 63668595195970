import React from 'react';

const Descargas = () => {
  return (
    <div className='text-left text-justify container fondotransparente3 p-5'>
      <center>
        <h2 className='h1 p-5 botonbordeazul text-white px-5 mx-5 py-3'>
          Descargas
        </h2>
      </center>

      <br />
      <br />

      <div className='margenazul'>
        <br />
        <br />
        <ul>
          <li
            className='h5 sinvineta'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', // Espacio entre las columnas
              marginBottom: '20px', // Espacio entre elementos
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a href="./download/suenos_1.jpg" download>
                Significado de los sueños pagina 1
              </a>
            </div>
            <div>
              <img
                src="./download/suenos_1.jpg"
                alt="Significado de los sueños pagina 1"
                style={{ maxWidth: '150px' }} // Ajusta el tamaño máximo de la miniatura
              />
            </div>
          </li>

          <li
            className='h5 sinvineta'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', // Espacio entre las columnas
              marginBottom: '20px', // Espacio entre elementos
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a href="./download/suenos_2.jpg" download>
                Significado de los sueños pagina 2
              </a>
            </div>
            <div>
              <img
                src="./download/suenos_2.jpg"
                alt="Significado de los sueños pagina 2"
                style={{ maxWidth: '150px' }} // Ajusta el tamaño máximo de la miniatura
              />
            </div>
          </li>

          {/*           <li
            className='h5 sinvineta'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', // Espacio entre las columnas
              marginBottom: '20px', // Espacio entre elementos
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a href="./download/placapremio.jpg" download>
                Placa de premio UNA MAS!
              </a>
            </div>
            <div>
              <img
                src="./download/placapremio.jpg"
                alt="Placa de premio UNA MAS!"
                style={{ maxWidth: '150px' }} // Ajusta el tamaño máximo de la miniatura
              />
            </div>
          </li> */}

          {/* {         <li
            className='h5 sinvineta'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', // Espacio entre las columnas
              marginBottom: '20px', // Espacio entre elementos
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a href="./download/placafecha.jpg" download>
                Placa de fechas UNA MAS!
              </a>
            </div>
            <div>
              <img
                src="./download/placafecha.jpg"
                alt="Placa de fechas UNA MAS!"
                style={{ maxWidth: '150px' }} // Ajusta el tamaño máximo de la miniatura
              />
            </div>
          </li> } */}

          <li
            className='h5 sinvineta'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', // Espacio entre las columnas
              marginBottom: '20px', // Espacio entre elementos
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a href="./download/juga_por_jugar.png" download>
                JUGA X JUGAR - Placa de Juego Responsable
              </a>
            </div>
            <div>
              <img
                src="./download/juga_por_jugar.png"
                alt="Juga por Jugar"
                style={{ maxWidth: '150px' }} // Ajusta el tamaño máximo de la miniatura
              />
            </div>
          </li>
          <li
            className='h5 sinvineta'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', // Espacio entre las columnas
              marginBottom: '20px', // Espacio entre elementos
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a href="./download/INPROJUY_Jugar-x-Jugar_FLYER_A6.jpg" download>
                JUGA X JUGAR - Flyer A6
              </a>
            </div>
            <div>
              <img
                src="./download/INPROJUY_Jugar-x-Jugar_FLYER_A6.jpg"
                alt="JUGA X JUGAR - Flyer A6"
                style={{ maxWidth: '150px' }} // Ajusta el tamaño máximo de la miniatura
              />
            </div>
          </li>
          <li
            className='h5 sinvineta'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', // Espacio entre las columnas
              marginBottom: '20px', // Espacio entre elementos
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a href="./download/juegoilegal.jpg" download>
                JUEGO ILEGAL - Placa              </a>
            </div>
            <div>
              <img
                src="./download/juegoilegal.jpg"
                alt="Juego Ilegal"
                style={{ maxWidth: '150px' }} // Ajusta el tamaño máximo de la miniatura
              />
            </div>
          </li>
          <li
            className='h5 sinvineta'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', // Espacio entre las columnas
              marginBottom: '20px', // Espacio entre elementos
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a href="./download/liquidacion1.jpg" download>
                Liquidación de premios
              </a>
            </div>
            <div>
              <img
                src="./download/liquidacion1.jpg"
                alt="liquidacion de premios"
                style={{ maxWidth: '150px' }} // Ajusta el tamaño máximo de la miniatura
              />
            </div>
          </li>
          <li className='h5 sinvineta' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a href="./download/liquidacion_redoblona.jpg" download>
                Liquidación Redoblona
              </a>
            </div>
            <div>
              <img src="./download/liquidacion_redoblona.jpg " alt="Liquidación redoblona" style={{ maxWidth: '150px' }} />
            </div>
          </li>
          <li className='h5 sinvineta' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a href="https://firebasestorage.googleapis.com/v0/b/inprojuy-f1173.appspot.com/o/QuiniNavidadA3.jpg?alt=media&token=a30ab61f-bf58-4020-adb0-07109aefee78" download>
                Quini 6 A3 Horizontal
              </a>
            </div>
            <div>
              <img src="https://firebasestorage.googleapis.com/v0/b/inprojuy-f1173.appspot.com/o/QuiniNavidadA3.jpg?alt=media&token=a30ab61f-bf58-4020-adb0-07109aefee78" alt="Liquidación redoblona" style={{ maxWidth: '150px' }} />
            </div>
          </li>
          <li className='h5 sinvineta' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a href="https://firebasestorage.googleapis.com/v0/b/inprojuy-f1173.appspot.com/o/QuiniNavidadVertical.jpg?alt=media&token=749c7027-a1d5-4f0a-bde4-2ee4d994fb45" download>
                Quini 6 A3 Vertical
              </a>
            </div>
            <div>
              <img src="https://firebasestorage.googleapis.com/v0/b/inprojuy-f1173.appspot.com/o/QuiniNavidadVertical.jpg?alt=media&token=749c7027-a1d5-4f0a-bde4-2ee4d994fb45" alt="Liquidación redoblona" style={{ maxWidth: '150px' }} />
            </div>
          </li>
          

        </ul>
        <br />
        <br />
      </div>
    </div>
  );
};

export default Descargas;
