import React from 'react'

const ManualProcedimientos = () => {
    return (
        <div className='container fondotransparente p-5 my-5 '>
            <center><h1 className=" p-5 botonbordeazul text-white px-5 mx-5 py-3">Manual de Política y Procedimiento del INPROJUY sobre el Lavado de Activos</h1></center>
            <br />
            <br />
            <p>
                El <strong>Manual de Política y Procedimiento del INPROJUY</strong> establece las medidas y acciones que el
                Instituto Provincial de Juegos de Azar de Jujuy (INPROJUY) ha implementado para prevenir y combatir el 
                <strong> lavado de activos y la financiación del terrorismo</strong>.
            </p>
            <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                        <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                            ¿Qué es el Lavado de Activos?
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                        <div className="accordion-body">

                            <p>
                                El lavado de activos es un proceso mediante el cual se intenta ocultar el origen ilegal de los fondos obtenidos a
                                través de actividades delictivas, integrándolos en el sistema económico formal. Este proceso se realiza en tres
                                fases:
                            </p>
                            <ul className="ul-con-vinetas">
                                <li><strong>Colocación</strong>: Se introducen fondos ilícitos en el sistema financiero.</li>
                                <li><strong>Decantación</strong>: Se llevan a cabo transacciones complejas para ocultar el origen de los fondos.</li>
                                <li><strong>Integración</strong>: Los fondos parecen legales y se utilizan como si se hubieran obtenido
                                    legítimamente.</li>
                            </ul>

                            <center>
        <div className=''>
          {/* Media query para dispositivos móviles */}
          <img
            className='img-fluid mobile-image'
            src='./img/etapas.jpg'
            alt='Imagen para dispositivos móviles'
          />

          {/* Media query para escritorio */}
          <img
            className='img-fluid desktop-image'
            src='./img/etapas.jpg'
            alt='Imagen para escritorio'
          />
        </div>
      </center>

                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                        <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                            ¿Qué es la Financiación del Terrorismo?
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                        <div className="accordion-body">
                            <p>
                                La financiación del terrorismo implica proporcionar bienes o dinero, que pueden ser de origen lícito o ilícito,
                                para apoyar actividades terroristas. Se refiere a cualquier forma de apoyo económico que ayude a realizar actos
                                terroristas.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                        <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                            Importancia para el Sector de Juegos de Azar
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                        <div className="accordion-body">
                            <p>
                                El sector de juegos de azar es considerado de alto riesgo para el lavado de dinero debido a la posibilidad de
                                realizar transacciones en efectivo y la facilidad con la que se pueden canalizar fondos ilícitos. Sin embargo, el
                                riesgo de financiación del terrorismo es relativamente menor.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                        <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                            Marco Normativo
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                        <div className="accordion-body">

                            <p>
                                El INPROJUY cumple con un marco normativo riguroso, establecido principalmente por las siguientes leyes:
                            </p>
                            <ul className="ul-con-vinetas">
                                <li><strong>Ley Nº 25.246/2000 y sus modificaciones</strong>: Define el lavado de activos y la financiación del
                                    terrorismo como delitos autónomos.</li>
                                <li><strong>Ley Nº 26.683/2011</strong>: Refuerza las medidas para prevenir estos delitos.</li>
                            </ul>
                            <p>
                                Estas leyes también crearon la <strong>Unidad de Información Financiera (UIF)</strong>, una agencia que supervisa
                                el cumplimiento de estas normativas y regula las actividades de los sujetos obligados, como el INPROJUY. La UIF
                                tiene la autoridad de exigir reportes, reglamentar procedimientos y coordinar con organismos internacionales como
                                el <strong>Grupo de Acción Financiera Internacional (GAFI)</strong>.
                            </p>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                            <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                                Medidas Implementadas por INPROJUY
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
                            <div className="accordion-body">
                                <h4>Medidas Implementadas por INPROJUY</h4>
                                <p>El INPROJUY ha adoptado diversas políticas y procedimientos para asegurar el cumplimiento de las normativas:</p>
                                <ul className="ul-con-vinetas">
                                    <li>
                                        <strong>Designación de un Oficial de Cumplimiento</strong>: Esta persona es responsable de supervisar las
                                        políticas y procedimientos internos para prevenir y detectar el lavado de dinero y la financiación del
                                        terrorismo.
                                    </li>
                                    <li>
                                        <strong>Creación de un Departamento de Auditoría y Lavado de Activos</strong>: Este departamento trabaja junto
                                        al Oficial de Cumplimiento para garantizar que todas las políticas se apliquen correctamente.
                                    </li>
                                    <li>
                                        <strong>Oficial de Enlace</strong>: Un representante designado para coordinar con la UIF y responder a sus
                                        requerimientos. Actualmente, esta función la desempeña el Dr. Víctor Farfán, designado mediante Decreto
                                        Nº 4001/2021.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingSix">
                            <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
                                Procedimientos de Prevención
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseSix" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSix">
                            <div className="accordion-body">
                                <h4>Procedimientos de Prevención</h4>
                                <p>
                                    El manual establece los métodos para detectar y reportar operaciones sospechosas. Se registran y analizan todas
                                    las transacciones inusuales y se informa a la UIF cuando se detectan posibles casos de lavado de dinero o
                                    financiación del terrorismo. Se presentan informes periódicos, cumpliendo con los plazos y requisitos de la UIF.
                                </p>
                                <p>
                                    El compromiso del INPROJUY con la prevención del lavado de activos y la financiación del terrorismo es un esfuerzo
                                    constante que busca garantizar la transparencia y la integridad de todas sus operaciones.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ManualProcedimientos